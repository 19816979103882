<template>
	<div>
		<h2 class="typo-h4 position-relative">
			Daily auto regenerate time
		</h2>
		<div class="mt-3">
			<div
				v-if="!localRegenerateTime || localRegenerateTime.length <= 0"
				class="list-empty color-black-45 d-flex flex-column rounded-sm"
			>
				<div class="d-flex justify-content-between px-4 pt-4">
					<p class="typo-label color-black-90 font-weight-bold mb-0">Time slot</p>
					<a
						href="#"
						class="typo-label color-hypertext float-right"
						@click="handleAddRegenerateTimeClick"
					>
						<CIcon
							class="icon-plus"
							name="cil-plus"
						/>
						Add new time slot
					</a>
				</div>
				<div class="d-flex align-items-center flex-fill justify-content-center">
					There is no time slot added.
				</div>
			</div>
			<div v-else class="product-feed-regenerate-time rounded-sm p-4 pb-2">
				<div class="d-flex justify-content-between">
					<p class="typo-label color-black-90 font-weight-bold mb-0">Time slot</p>
					<a
						href="#"
						class="typo-label color-hypertext float-right"
						@click="handleAddRegenerateTimeClick"
					>
						<CIcon
							class="icon-plus"
							name="cil-plus"
						/>
						Add new time slot
					</a>
				</div>
				<div
					v-for="(vRegenerateTime, index) in $v.localRegenerateTime.$each.$iter"
					:key="index"
					class="time-slots d-flex align-items-top my-4"
				>
					<BaseInputTime
						v-model="vRegenerateTime.time.$model"
						:is-valid="!vRegenerateTime.time.$error"
						:invalid-feedback="timeSlotErrors(vRegenerateTime ,index)"
					/>
					<a
						href="#"
						class="btn"
						@click="handleRemoveRegenerateTimeClick(index)"
					>
						<CIcon
							class="icon-trash"
							name="cil-trash"
						/>
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { timeFormat } from '../assets/js/validators';

export default {
	name: 'RegenerateTimes',
	validations: {
		localRegenerateTime: {
			$each: {
				time: {
					required,
					format: (value) => {
						return timeFormat(value);
					},
				},
			},
		},
	},
	props: {
		regenerateTimes: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			localRegenerateTime: this.regenerateTimes.map((time) => ({ time })),
		};
	},
	watch: {
		localRegenerateTime: {
			deep: true,
			handler(data) {
				if (this.$v.$invalid) {
					return;
				}

				const inputTime = data.map(({ time }) => time);
				this.$emit('update:regenerate-times', inputTime);
			},
		},
	},
	methods: {
		handleAddRegenerateTimeClick() {
			this.$v.$touch();
			if (!this.$v.$invalid) {
				this.localRegenerateTime.push({ time: null });
			}
		},
		handleRemoveRegenerateTimeClick(index) {
			this.localRegenerateTime.splice(index, 1);
		},
		timeSlotErrors(obj) {
			if (!obj.required) {
				return 'Required field';
			}

			if (!obj.format) {
				return `Time format is invalid`;
			}

			return null;
		},
	},
};
</script>

<style lang="scss" scoped>
	.product-condition-box {
		background-color: $color-gray-100;
	}

	.product-feed-regenerate-time {
		background-color: $color-gray-100;
		min-height: rem(140);
	}

	.list-empty {
		height: rem(140);
		background-color: $color-gray-100;
	}

	.is-invalid {
		border: 1px solid $color-alert;
		border-radius: rem(4);
	}

	.invalid-feedback {
		display: block;
	}
</style>

