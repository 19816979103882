<template>
	<div class="main-wrapper">
		<form
			class="form-group-image mt-4"
			@submit.prevent="handleSubmit"
		>
			<CRow v-if="isEditMode">
				<CCol
					md="12"
					class="mb-4"
				>
					<h4>{{ partner }}</h4>
					<p class="product-feed-info">
						Last updated: <span class="product-feed-user">{{ updatedBy }}</span>
						<time class="product-feed-updated"> | {{ lastUpdated }}</time>
					</p>
				</CCol>
			</CRow>
			<CRow>
				<CCol
					md="12"
					class="d-flex justify-content-between align-items-center mb-4"
				>
					<h4 class="mb-0">
						General settings
					</h4>
					<div class="marketing-category-id">
						<span class="typo-caption-2 mr-1">Partner ID:</span>
						<span class="typo-caption-2 font-weight-bold">
							{{ isEditMode ? $route.params.id : 'Generate after saving' }}
						</span>
					</div>
				</CCol>
			</CRow>
			<CRow>
				<CCol md="12">
					<CInput
						v-model.trim="$v.formData.partner.$model"
						:readonly="isReadOnlyPartnerName"
						:label="`Partner${isReadOnlyPartnerName ? '' : '*'}`"
						:is-valid="!$v.formData.partner.$error && null"
						:invalid-feedback="$t('global.error.required')"
						type="text"
						@blur="handleAutoFillMetaSlug"
					/>
				</CCol>
			</CRow>
			<CRow>
				<CCol md="12">
					<CInput
						v-model.trim="$v.formData.slug.$model"
						:is-valid="!$v.formData.slug.$error && null"
						:invalid-feedback="getSlugError()"
						:description="getSlugDescription()"
						type="text"
						label="Slug*"
					/>
				</CCol>
			</CRow>
			<CRow>
				<CCol md="12">
					<CInput
						v-model.trim="$v.formData.fileName.$model"
						:is-valid="!$v.formData.fileName.$error && null"
						:invalid-feedback="$t('global.error.required')"
						type="text"
						label="File name*"
					/>
				</CCol>
			</CRow>
			<CRow>
				<CCol md="12">
					<CInput
						:value="formData.fullUrl"
						readonly
						label="URL"
						type="text"
					/>
				</CCol>
			</CRow>
			<CRow>
				<CCol md="12">
					<div class="form-group form-group-switch mt-1">
						<label class="toggle-label" for="status">Product feed status</label>
						<CSwitch
							id="status"
							:checked.sync="formData.status"
							variant="3d"
							size="sm"
							class="switch-custom"
							color="success"
						/>
						<span>{{ formData.status ? 'Active' : 'Inactive' }}</span>
					</div>
				</CCol>
			</CRow>
			<CRow>
				<CCol md="12">
					<hr class="my-4">
				</CCol>
			</CRow>
			<CRow class="mb-4">
				<CCol>
					<RegenerateTimes
						ref="regenerate-time"
						:regenerate-times="regenerateTime"
						@update:regenerate-times="handleRegenerateTimeChange"
					/>
				</CCol>
			</CRow>
			<CRow>
				<CCol md="12">
					<hr class="my-4">
				</CCol>
			</CRow>
			<!-- Product condition -->
			<CRow>
				<CCol md="12">
					<ProductCondition
						:default-data="productConditions"
						title="Products data feed"
						is-rule-type-all
						no-condition-match
						no-subkey
						@onUpdate="handleProductConditionChange"
					/>
				</CCol>
			</CRow>
			<CRow>
				<CCol md="12">
					<hr class="mt-5">
				</CCol>
			</CRow>
			<!-- Remarks -->
			<CRow v-if="isEditMode">
				<CCol md="12">
					<ProductFeedRemarks
						:remarks.sync="formData.remarks"
						:product-feed-id="formData.id"
					/>
				</CCol>
			</CRow>
			<!-- End Remarks -->
			<BaseActionPanelStickyFooter
				:disabled-confirm="isSubmitting"
				:is-edit="isEditMode"
				:button-edit-confirm-text="isSubmitting ? 'Saving...' : 'Save changes'"
				:remove-text="isEditMode && isDeletable ? 'Remove product feed' : null"
				content-class="main-wrapper"
				@onConfirm="handleSubmit"
				@onCancel="$router.push({ name: 'ProductFeedList'})"
				@onRemove="isEditMode ? $refs['modal-remove'].open() : null"
			/>
		</form>

		<BaseModalConfirmDelete
			ref="modal-remove"
			:handle-remove="deleteFeed.bind(null, id)"
			description="Are you sure you want to delete this product feed?"
			title="Remove this product feed?"
			is-confirm-required
			@onSuccess="$router.push({ name: 'ProductFeedList'})"
		/>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import { required, requiredIf, helpers } from 'vuelidate/lib/validators';
import RegenerateTimes from '@/components/RegenerateTimes.vue';
import ProductFeedRemarks from '@/components/ProductFeedRemarks.vue';
import ProductCondition from '@/components/ProductCondition.vue';
import { scrollToTop, convertTimeToUTC } from '../assets/js/helpers';
import { transformedProductConditionToAPI } from '../assets/js/transform/promotions';

const slugify = require('slugify');

export default {
	name: 'FormProductFeed',
	components: {
		RegenerateTimes,
		ProductFeedRemarks,
		ProductCondition,
	},
	validations() {
		return {
			formData: {
				partner: { required: requiredIf((vm) => !vm.isEditMode) },
				slug: {
					required,
					// Allow letters; numbers; and ( - )
					isValid: helpers.regex('isValid', /^[a-zA-Z0-9-]*$/),
				},
				fileName: {
					required,
				},
			},
		};
	},
	props: {
		isEditMode: {
			type: Boolean,
			default: false,
		},
		id: {
			type: Number,
			default: null,
		},
		partner: {
			type: String,
			default: null,
		},
		slug: {
			type: String,
			default: null,
		},
		fileName: {
			type: String,
			default: null,
		},
		status: {
			type: Boolean,
			default: false,
		},
		lastUpdated: {
			type: String,
			default: null,
		},
		updatedBy: {
			type: String,
			default: null,
		},
		regenerateTime: {
			type: Array,
			default: () => [],
		},
		baseUrl: {
			type: String,
			default: null,
		},
		template: {
			type: String,
			default: null,
		},
		isDeletable: {
			type: Boolean,
			default: false,
		},
		remarks: {
			type: Array,
			default: () => [],
		},
		productCondition: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			isSubmitting: false,
			formData: {
				id: null,
				partner: null,
				slug: null,
				fileName: null,
				fullUrl: null,
				status: false,
				remarks: null,
			},
			productConditions: null,
			regenerateTimes: [],
		};
	},
	computed: {
		fullUrl() {
			return `${this.baseUrl}/${this.formData.slug || '*'}/${this.formData.fileName || '*'}`;
		},
		isReadOnlyPartnerName() {
			return this.isEditMode && !this.isDeletable;
		},
	},
	watch: {
		fullUrl(val) {
			this.formData.fullUrl = val;
		},
	},
	async created() {
		this.formData = {
			id: this.id,
			partner: this.partner,
			slug: this.slug,
			fileName: this.fileName,
			fullUrl: this.fullUrl,
			status: this.status,
			remarks: this.remarks,
		};
		this.regenerateTimes = this.regenerateTime;
		this.productConditions = this.productCondition;
	},
	methods: {
		...mapActions({
			createFeed: 'feeds/createFeed',
			updateFeed: 'feeds/updateFeed',
			deleteFeed: 'feeds/deleteFeed',
		}),
		getSlugError() {
			if (!this.$v.formData.slug.required) {
				return this.$t('global.error.required');
			}

			if (!this.$v.formData.slug.isValid) {
				return this.$t('global.slug.invalid');
			}
			return null;
		},
		getSlugDescription() {
			if (this.$v.formData.slug.$error) {
				return '';
			}
			return this.$t('global.slug.slugFormat');
		},
		handleProductConditionChange(condition) {
			this.productConditions = condition;
		},
		handleRegenerateTimeChange(time) {
			this.regenerateTimes = time;
		},
		handleAutoFillMetaSlug() {
			if (!this.formData.slug) {
				this.formData.slug = slugify(this.formData.partner, {
					lower: true,
					strict: true,
				});
				this.$v.formData.slug.$touch();
			}
		},
		async handleSubmit() {
			const refRegenerateTime = this.$refs['regenerate-time'];
			this.$v.$touch();
			refRegenerateTime.$v.$touch();
			if (this.$v.$invalid || refRegenerateTime.$v.$invalid) {
				scrollToTop();
				return;
			}

			this.isSubmitting = true;

			const params = {

				name: this.formData.partner,
				slug: this.formData.slug,
				file_name: this.formData.fileName,
				is_active: this.formData.status,
				template: this.template,
				is_deletable: this.isDeletable,
				regenerate_times: this.regenerateTimes.map((time) => convertTimeToUTC(time)),
				item_condition_param: transformedProductConditionToAPI(this.productConditions),
			};

			try {
				if (!this.isEditMode) {
					await this.createFeed({
						...params,
						is_deletable: true,
					});

					this.$router.push({ name: 'ProductFeedList' });
				} else {
					await this.updateFeed({
						id: this.id,
						params: {
							...params,
							id: this.formData.id,
						},
					});
				}
			} finally {
				this.isSubmitting = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	.product-feed {
		&-info {
			color: $color-black-90;
			font-size: rem($font-size-label);
		}

		&-updated {
			color: $color-black-45;
		}

		&-user {
			color: $color-hypertext;
		}
	}
</style>
